import { FC } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.svg'
import InstagramLogoColor from '../assets/icons/social-media/color/instagram.svg'
import FacebookLogoColor from '../assets/icons/social-media/color/facebook.svg'
import InstagramLogoBlackWhite from '../assets/icons/social-media/black-and-white/instagram.svg'
import FacebookLogoBlackWhite from '../assets/icons/social-media/black-and-white/facebook.svg'

// https://tw-elements.com/docs/react/components/social-buttons/

const Footer: FC = () => {
   return (
      <footer className="bg-primary-codGray">
         <div className="container py-20 text-center md:text-left md:flex animate animate-fade-in">
            <Link to="/" className="w-[7rem] h-30">
               <img src={Logo} alt="Logo" />
            </Link>

            <div 
               className="uppercase text-sm leading-7 font-normal text-white tracking-widest 
                           md:ml-32 xl:ml-48 xl:flex items-center justify-between"
            >
               <div className="flex flex-col xl:flex-row items-center xl:items-start xl:mr-44">
                  <div className="flex items-center space-x-4 h-20">
                        <div>Commandez ici! @el_fourna</div>
                        <div className="flex space-x-2">
                           <div className="relative group w-6 h-6">
                              <a href='https://www.instagram.com/el_fourna/'>
                                 <img 
                                       src={InstagramLogoBlackWhite} 
                                       alt="Instagram Logo B&W" 
                                       className="absolute inset-0 transition-opacity duration-300 opacity-100 group-hover:opacity-0"
                                 />
                                 <img 
                                       src={InstagramLogoColor} 
                                       alt="Instagram Logo Color" 
                                       className="absolute inset-0 transition-opacity duration-300 opacity-0 group-hover:opacity-100"
                                 />
                              </a>
                           </div>
                           <div className="relative group w-6 h-6">
                              <a href='https://www.facebook.com/profile.php?id=61570062345553'>
                                 <img
                                       src={FacebookLogoBlackWhite} 
                                       alt="Facebook Logo B&W" 
                                       className="absolute inset-0 transition-opacity duration-300 opacity-100 group-hover:opacity-0"
                                 />
                                 <img 
                                       src={FacebookLogoColor} 
                                       alt="Facebook Logo Color" 
                                       className="absolute inset-0 transition-opacity duration-300 opacity-0 group-hover:opacity-100"
                                 />
                              </a>
                           </div>
                        </div>
                  </div>
               </div>

               <div className="text-center xl:text-left">
                  Suivez-nous sur nos réseaux sociaux pour découvrir nos nouveautés!
               </div>
            </div>

         </div>
      </footer>
   )
}

export default Footer
