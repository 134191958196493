import { FC } from 'react'

const ReservationHeading: FC = () => {
   return (
      <div
         className="flex flex-col items-center xl:items-start text-white 
         text-center xl:text-left">
         <h1>Notre menu</h1>
         <p className="max-w-[35.8125rem] xl:max-w-md mt-3 mb-5 md:mb-0">
            Nous préparons de savoureuses gourmandises salées, fraîches et sur commande, pour tous vos événements. Que ce soit une réunion, un événement d'entreprise ou une célébration, notre menu varié ravira vos invités. Fait avec amour et des ingrédients de qualité. Rendez votre événement inoubliable !
         </p>
      </div>
   )
}

export default ReservationHeading
