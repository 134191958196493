import { FC } from 'react'
import HighlightItem from './HighlightItem'
// Images
import chaussonImg from '../../assets/homepage/chausson_plateau.jpg'
import beefImg from '../../assets/homepage/pizza_tuna.jpg'
import chocolateImg from '../../assets/homepage/m7ajeb.jpg'

const HighlightItems: FC = () => {
   return (
      <div
         className="mt-14 mx-auto xl:mx-0 max-w-[35.8125rem] xl:max-w-[33.75rem]
         animate animate-fade-in">
         <HighlightItem
            imgSrc={chaussonImg}
            heading="Chausson au poulet"
            paragraph="Notre chausson au poulet, garni d’une farce savoureuse et enveloppé dans une pâte dorée et croustillante, un vrai régal fait maison."
         />
         <HighlightItem
            imgSrc={beefImg}
            heading="Salé au thon"
            paragraph="Notre salé au thon, délicatement garni de thon et de fromage fondant, le tout sur une pâte dorée pour un plaisir garanti."
         />
         <HighlightItem
            imgSrc={chocolateImg}
            heading="Mhadjeb"
            paragraph="Notre mhadjeb, farci de légumes savoureux et délicatement assaisonnés, pour un mélange parfait de textures et de saveurs authentiques."
         />
      </div>
   )
}

export default HighlightItems
