// images
import familyGatheringImg from '../assets/homepage/event_family_desktop.jpg'
import familyGatheringImgTab from '../assets/homepage/event_family_tablet.jpg'
import familyGatheringImgDt from '../assets/homepage/event_family_mobile.jpg'
import weddingEventImg from '../assets/homepage/event_wedding_desktop.jpg'
import weddingEventImgTab from '../assets/homepage/event_wedding_tablet.jpg'
import weddingEventImgDt from '../assets/homepage/event_wedding_mobile.jpg'
import businessEventsImg from '../assets/homepage/event_business_desktop.jpg'
import businessEventsImgTab from '../assets/homepage/event_business_tablet.jpg'
import businessEventsImgDt from '../assets/homepage/event_business_mobile.jpg'

// Static data for events section
export const eventsData = [
    {
        name: 'Mariage',
        paragraph:
            'Nous mettons tout notre cœur dans l’organisation de repas de mariage. Confiez-nous ce jour exceptionnel, et nous ferons en sorte que chaque bouchée soit un souvenir inoubliable, pour vous et vos invités.',
        img: [weddingEventImg,weddingEventImgTab,weddingEventImgDt],
    },
    {
        name: 'Événements affaire',
        paragraph:
            'Nous sommes spécialisés dans les repas pour événements professionnels. Laissez-nous sublimer vos rencontres d\'affaires avec une expérience culinaire raffinée, qui marquera les esprits et renforcera vos échanges.',
        img: [businessEventsImg,businessEventsImgTab,businessEventsImgDt],
    },
    {
        name: 'Événements familiaux et sociaux',
        paragraph:
            'Nous sommes spécialisés dans l\'organisation de repas pour tous vos événements sociaux, qu\'il s\'agisse de réunions familiales, de fêtes ou d\'occasions spéciales. Laissez-nous créer un moment inoubliable pour vous et vos invités.',
        img: [familyGatheringImg,familyGatheringImgTab,familyGatheringImgDt],
    }
]