import { FC } from 'react'
import Feature from './Feature'
// images
import enjoyablePlace from '../../assets/homepage/min_tuna_sandwich.jpg'
import locallySourced from '../../assets/homepage/mini_pizza.jpg'

const Features: FC = () => {
   return (
      <div className="container flex flex-col xl:gap-30">
         <Feature
            imgSrc={enjoyablePlace}
            heading="Batbout au thon"
            paragraph="Tous nos ingrédients sont soigneusement sélectionnés et préparés maison. Vous pouvez ainsi être sûr de savourer des produits frais et de la meilleure qualité."
         />
         <Feature
            imgSrc={locallySourced}
            heading="Mini pizza au poulet"
            paragraph="Nous privilégions des ingrédients de première qualité, issus de producteurs locaux, pour garantir des plats frais et savoureux."
         />
      </div>
   )
}

export default Features
